.default {
  --line-height: 20px;
}

@keyframes blur {
  0% {
    opacity: 0.95;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.95;
  }
}

.vhs {
  --background: blue;
  --border-color: yellow;
  --color: yellow;
  --font-weight: bold;

  animation: blur 100ms infinite;
  background: var(--background);
  color: var(--color);
  filter: blur(0.5px);
  font-family: arial, sans-serif;
  text-shadow: 1px 1px green, -1px -1px red;
  transition: background 200ms, color 200ms;
}

.new-wave {
  --background: blue;
  --border-color: cyan;
  --border-radius: 4px;
  --border-width: 2px;
  --box-shadow: 4px 4px 0 0 magenta;
  --color: cyan;
  --padding: 12px;

  --active-box-shadow: 8px 8px 0 0 magenta;
  --active-transform: translate(-4px, -4px);

  background: var(--background);
  color: var(--color);
  font-family: 'Saira', sans-serif;
  transition: background 200ms, color 200ms;
}

.gameboy {
  --background: yellowgreen;
  --border-color: darkolivegreen;
  --border-width: 3px;
  --box-shadow: 4px 4px 0 0 olivedrab;
  --color: darkolivegreen;
  --font-size: 18px;
  --line-height: 32px;
  --font-weight: 600;
  --margin: 16px;
  --padding: 16px;

  --active-box-shadow: 8px 8px 0 0 olivedrab;
  --active-transform: translate(-4px, -4px);

  background: var(--background);
  color: var(--color);
  font-family: 'Saira', sans-serif;
  transition: background 200ms, color 200ms;
}
